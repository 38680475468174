import {generateRandomString} from '@mehimself/cctypehelpers';

const randomBoxNumber = () => Math.floor(Math.random() * 900000) + 100000
const randomId = () => generateRandomString(8, 'alphanumeric')

const data = [
    {
        department: 'Department 1',
        subject: 'Subject 1',
        fromTo: '1998 - 2020, 2021',
        keepUntil: '2040',
        boxNumber: randomBoxNumber(),
        filedBy: 'AVI Main Archive',
        extIdNumber: 'Ex1',
        location: '05 03 07',
    },
    {
        department: 'Department 2',
        subject: 'lorem ispum dolor met sit lorem ispum dolor met sit lorem ispum dolor met sit lorem ispum dolor met sit lorem ispum dolor met sit lorem ispum dolor met sit lorem ispum dolor met sit lorem ispum dolor met sit lorem ispum dolor met sit lorem ispum dolor met sit lorem ispum dolor met sit lorem ispum dolor met sit lorem ispum dolor met sit lorem ispum dolor met sit lorem ispum dolor met sit lorem ispum dolor met sit lorem ispum dolor met sit lorem ispum dolor met sit lorem ispum dolor met sit lorem ispum dolor met sit lorem ispum dolor met sit lorem ispum dolor met sit lorem ispum dolor met sit lorem ispum dolor met sit lorem ispum dolor met sit lorem ispum dolor met sit lorem ispum dolor met sit lorem ispum dolor met sit lorem ispum dolor met sit lorem ispum dolor met sit lorem ispum dolor met sit lorem ispum dolor met sit lorem ispum dolor met sit lorem ispum dolor met sit lorem ispum dolor met sit lorem ispum dolor met sit lorem ispum dolor met sit lorem ispum dolor met sit lorem ispum dolor met sit lorem ispum dolor met sit lorem ispum dolor met sit lorem ispum dolor met sit lorem ispum dolor met sit lorem ispum dolor met sit lorem ispum dolor met sit lorem ispum dolor met sit lorem ispum dolor met sit lorem ispum dolor met sit ',
        fromTo: '2018 - 2020',
        keepUntil: 'Historical',
        boxNumber: randomBoxNumber(),
        filedBy: 'DAD',
        extIdNumber: 'Ex2',
        location: 'S DD 05',
    },
    {
        department: 'Department 3',
        subject: 'Subject 3',
        fromTo: '1950 - 1990',
        keepUntil: '2000',
        boxNumber: randomBoxNumber(),
        filedBy: 'AFL030',
        extIdNumber: 'Ex3',
        location: '08 00 00',
    },
    {
        department: 'Department 4',
        subject: 'Subject 4',
        fromTo: '1960 - 1972',
        keepUntil: '1990',
        boxNumber: 161322,
        filedBy: 'DAD',
        extIdNumber: 'Ex4',
        location: '08 44 01',
    },
]

export const placeholderData = Object.fromEntries(
    data.map(datum => {
        const id = randomId()
        return [id, {
            ...datum,
            id,
        }]
    })
)