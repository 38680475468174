import {
    INIT_STUDY_SUITE,
    INIT_LOCAL_SUITE,
    START_GAME,
    START_GAME_ROUND,
    LOG_USER_PROMPT,
    LOG_USER_RESPONSE,
    END_GAME,
    GENERATE_DATA_FILES,
    LIST_DATA_FILES
} from './operations.js';
import {throttleBufferedAction} from "@/store/lib/throttledBufferedAction";
import {getDelphiUrl} from "@/store/lib/apiUrls";
import {handleResponse} from "@/store/actions/lib/handleResponse";
import axios from "@/plugins/axios";
import {getDataTypeActionUrl} from "@/store/actions/lib/getDataTypeActionUrl";
import {getDocumentActionUrl} from "@/store/actions/lib/getDocumentActionUrl";

const _appId = 'delphi'
export default {
    [INIT_STUDY_SUITE](context, {_participantId, parentOrigin, resetParticipantForTesting}) { // do not touch before 2050
        const url = getDataTypeActionUrl({
            _appId,
            _dataType: 'Suite',
            action: 'init'
        })
        return throttleBufferedAction({
            action: axios.post,
            url,
            params: {
                parentOrigin,
                _participantId,
                resetParticipantForTesting
            }
        })
            .then(handleResponse(context, INIT_STUDY_SUITE))
    },
    [INIT_LOCAL_SUITE](context, {_participantId}) {
        const url = getDataTypeActionUrl({
            _appId,
            _dataType: 'Suite',
            action: 'initLocal'
        })
        return throttleBufferedAction({
            action: axios.post,
            url: url,
            params: {
                _participantId
            }
        })
            .then(handleResponse(context, INIT_LOCAL_SUITE))
    },
    [START_GAME](context, {_suiteId, gameName, _participantId, attributes}) {
        const url = getDataTypeActionUrl({
            _appId,
            _dataType: 'Game',
            action: 'startGame'
        })
        attributes = {
            ...attributes,
            _suiteId,
            gameName,
            _participantId
        }
        return throttleBufferedAction({
            action: axios.post,
            url: url,
            params: {
                _participantId,
                _suiteId,
                gameName,
                attributes
            }
        })
            .then(response => {
                const resumables = context.state.delphi._resumableGames
                resumables.splice(resumables.indexOf(gameName), 1) // make game non-resumable
                console.log(gameName, resumables)
                return handleResponse(context, START_GAME)(response)
            })
    },
    [START_GAME_ROUND](context, {_suiteId, _gameId, _participantId, attributes}) {
        const url = getDataTypeActionUrl({
            _appId,
            _dataType: 'Round',
            action: 'startRound'
        })
        attributes = {
            ...attributes,
            _gameId,
            _participantId
        }
        return throttleBufferedAction({
            action: axios.post,
            url: url,
            params: {
                attributes
            }
        })
            .then(handleResponse(context, START_GAME_ROUND))
    },
    [LOG_USER_PROMPT](context, {_suiteId, _gameId, _participantId, attributes}) {
        const url = getDataTypeActionUrl({
            _appId: 'delphi',
            _dataType: 'Prompt',
            action: 'logPrompt'
        })
        attributes = {
            ...attributes,
            _gameId,
            _participantId
        }
        return axios.post(url, {
                attributes
        })
            .then(handleResponse(context, LOG_USER_PROMPT))
    },
    [LOG_USER_RESPONSE](context, {_suiteId, _gameId, _promptId, _participantId, attributes}) {
        const url = getDataTypeActionUrl({
            _appId,
            _dataType: 'Response',
            action: 'logResponse'
        })
        attributes = {
            ...attributes,
            _gameId,
            _participantId,
            _promptId
        }
        return axios.post(url, {
                attributes
        })
            .then(handleResponse(context, LOG_USER_RESPONSE))
    },
    [END_GAME](context, {_suiteId, _gameId, attributes}) {
        const url = getDocumentActionUrl({
            _appId,
            _dataType: 'Game',
            _id: _gameId,
            action: 'endGame'
        })
        attributes = {
            ...attributes,
            _gameId,
        }
        return throttleBufferedAction({
            action: axios.post,
            url: url,
            params: {
                attributes,
            }
        })
            .then(handleResponse(context, END_GAME))
    },
    [LIST_DATA_FILES](context) {
        const url = getDataTypeActionUrl({
            _appId,
            _dataType: 'File',
            action: 'list'
        })
        return throttleBufferedAction({
            action: axios.post,
            url
        })
            .then(handleResponse(context, LIST_DATA_FILES))
    },
    [GENERATE_DATA_FILES](context) {
        const url = getDataTypeActionUrl({
            _appId,
            _dataType: 'File',
            action: 'generate'
        })
        return throttleBufferedAction({
            action: axios.post,
            url
        })
            .then(handleResponse(context, GENERATE_DATA_FILES))
    }
}