import {throttleBufferedAction} from '../lib/throttledBufferedAction.js';
import axios from '../../plugins/axios.js';
import {handleResponse} from './lib/handleResponse.js';
import {
    CREATE_DOCUMENT,
    LOG_DOCUMENT,
    DELETE_DOCUMENT,
    GET_DOCUMENT,
    LIST_DOCUMENTS,
    PATCH_DOCUMENT,
    PUBLISH_DOCUMENT,
    RETRACT_DOCUMENT,
} from '../operations/documentOperations.js';

import {getDataTypeActionUrl} from './lib/getDataTypeActionUrl.js';
import {getDocumentActionUrl} from './lib/getDocumentActionUrl.js';

export default {
    [LIST_DOCUMENTS](context,
                  {
                      _appId,
                      _dataType,
                      filter,
                      offset,
                      limit,
                      sorting,
                  }) {
        const options = {
            _appId,
            _dataType,
            offset,
            limit,
            sorting,
            filter
        }
        let url = getDataTypeActionUrl({
            _appId,
            _dataType,
            endpoint: 'list'
        })
        return throttleBufferedAction({
            action: axios.post,
            url,
            params: options,
        })
            .then(handleResponse(context, LIST_DOCUMENTS))
    },
    [CREATE_DOCUMENT](context, {
        _appId,
        _dataType,
        attributes
    }) {
        if (!_appId) throw new Error('_appId is required')
        if (!_dataType) throw new Error('_dataType is required')

        const url = getDataTypeActionUrl({
            _appId,
            _dataType,
            endpoint: 'create'
        })
        const params = {
            ...attributes,
        }

        return throttleBufferedAction({
            action: axios.post,
            url,
            params
        })
            .then(handleResponse(context, CREATE_DOCUMENT))
    },
    [LOG_DOCUMENT](context, {
        _appId,
        _dataType,
        attributes
    }) {
        if (!_appId) throw new Error('_appId is required')
        if (!_dataType) throw new Error('_dataType is required')

        const url = getDataTypeActionUrl({
            _appId,
            _dataType,
            endpoint: 'log'
        })
        const params = {
            ...attributes,
        }

        return throttleBufferedAction({
            action: axios.post,
            url,
            params
        })
            .then(handleResponse(context, CREATE_DOCUMENT))
    },
    [GET_DOCUMENT](context,
                {
                    _appId,
                    _dataType,
                    _id,
                }) {
        const params = {}
        return throttleBufferedAction({
            action: axios.post,
            url: getDocumentActionUrl({
                _appId,
                _dataType,
                _id,
                endpoint: 'get'
            }),
            params
        })
            .then(handleResponse(context, GET_DOCUMENT))
    },
    [PATCH_DOCUMENT](context,
                 {
                     _appId,
                     _dataType,
                     _id,
                     attributes,
                 }) {
        if (!_appId) throw new Error('_appId is required')
        if (!_dataType) throw new Error('_dataType is required')
        if (!_id) throw new Error('_id is required')

        const url = getDocumentActionUrl({
            _appId,
            _dataType,
            _id,
            endpoint: 'patch'
        })
        const params = {
            update: attributes,
        }

        return throttleBufferedAction({
            action: axios.patch,
            url,
            params,
        })
            .then(handleResponse(context, PATCH_DOCUMENT))
    },
    [PUBLISH_DOCUMENT](context,
                           {
                               _appId,
                               _dataType,
                               _id,
                           }) {
        if (!_appId) throw new Error('_appId is required')
        if (!_dataType) throw new Error('_dataType is required')
        if (!_id) throw new Error('_id is required')

        const url = getDocumentActionUrl({
            _appId,
            _dataType,
            _id,
            endpoint: `publish`
        })

        return throttleBufferedAction({
            action: axios.put,
            url,
        })
            .then(handleResponse(context, PUBLISH_DOCUMENT))
    },
    [RETRACT_DOCUMENT](context,
                           {
                               _appId,
                               _dataType,
                               _id,
                           }) {
        if (!_appId) throw new Error('_appId is required')
        if (!_dataType) throw new Error('_dataType is required')
        if (!_id) throw new Error('_id is required')

        const url = getDocumentActionUrl({
            _appId,
            _dataType,
            _id,
            endpoint: 'retract'
        })

        return throttleBufferedAction({
            action: axios.put,
            url,
        })
            .then(handleResponse(context, RETRACT_DOCUMENT))
    },
    [DELETE_DOCUMENT](context, {_appId, _dataType, _id}) {
        return throttleBufferedAction({
            action: axios.post,
            url: getDocumentActionUrl({
                _appId,
                _dataType,
                _id,
                endpoint: 'delete'
            })
        })
            .then(handleResponse(context, DELETE_DOCUMENT))
    },
}
