import {
  isArrayOfStrings,
  isJSIdentifier,
  isObject,
} from "@mehimself/cctypehelpers";
import { isRef, ref } from "vue";

const validateFilterAttribute = (attribute, path) => {
  const { field, operator, value } = attribute;
  if (!isJSIdentifier(field))
    throw new Error(`${path}.field must be a valid JavaScript identifier`);
  if (!isJSIdentifier(operator))
    throw new Error(`${path}.operator must be a valid JavaScript identifier`);
  if (operator === "in" && !isArrayOfStrings(value))
    throw new Error(`${path}.operator "in" requires an array of strings`);
};
export const ingestFilter = (filter, testPath = "filter") => {
  /*
        check if filter structure is acceptable

        filter:
            multi-attribute filter syntax:
            {
              type: "AND", // Logical operator
              filters: [
                {
                  field: "age",
                  operator: "greater_than",
                  value: 30
                },
                {
                  type: "OR",
                  filters: [
                    {
                      field: "name",
                      operator: "contains",
                      value: "John"
                    },
                    {
                      field: "skills",
                      operator: "in",
                      value: ["Python", "JavaScript"]
                    }
                  ]
                }
              ]
            }

            alternate simple filter syntax:
            {
              field: "age",
              operator: "greater_than",
              value: 30
            }
     */
  filter = filter ?? {};
  const refFilter = isRef(filter) ? filter : ref(filter);
  if (!isObject(refFilter.value))
    throw new Error(`${testPath} must be an object`);
  if (Object.keys(refFilter.value).length === 0) return refFilter;

  const { type, filters, field, operator, value } = refFilter.value;
  if (type && filters) {
    // multi-attribute filter
    if (type === "AND" || type === "OR") {
      filters.forEach((f, i) => ingestFilter(f, `${testPath}.filters[${i}]`));
    } else {
      throw new Error('filter.type must be "AND" or "OR"');
    }
  } else if ((field, operator, value)) {
    // attribute filter
    validateFilterAttribute(refFilter, "filter");
  } else {
    throw new Error(
      "filter must have either a type and filters array, or afield, an operator, and a value"
    );
  }
  return refFilter;
};
