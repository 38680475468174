import { assertIsSupportedOperation } from "@/store/lib/veriffyOperation";
import { isArrayOfStrings, isObject } from "@mehimself/cctypehelpers";
import { normalizeDocumentOperationKeys } from "@/composables/document/lib/normalizeDocumentOperationKeys";
import {
    CREATE_DOCUMENT,
    LIST_DOCUMENTS,
} from "@/store/operations/documentOperations";
import { isJSIdentifier } from "@mehimself/cctypehelpers";
import { computed, isRef, onMounted, reactive, ref, toRefs, watch } from "vue";
import { useStore } from "vuex";
import { filterDocuments } from "@/composables/document/lib/filterDocuments";
import { ingestFilter } from "@/composables/document/lib/ingestFilter.js";

const debug = {
    list: false,
    create: false,
};

const verifyOperations = (operations) => {
    Object.values(operations).forEach(assertIsSupportedOperation);
};

const ingestOperations = (operations) => {
    operations = operations || {};
    if (!isObject(operations)) throw new Error("operations must be an object");

    normalizeDocumentOperationKeys(operations);

    if (!operations.listDocuments) operations.listDocuments = LIST_DOCUMENTS;
    if (!operations.createDocument) operations.createDocument = CREATE_DOCUMENT;

    verifyOperations(operations);
    return operations;
};
const ingestGenerators = (generators) => {
    generators = generators || {};
    if (!isObject(generators)) throw new Error("generators must be an object");
    return generators;
};
const ingestAlias = (alias) => {
    if (!isJSIdentifier(alias))
        throw new Error("alias must be a valid JavaScript identifier");
    return alias;
};
const ingestOptions = (options) => {
    options = options || {};
    if (!isObject(options)) throw new Error("options must be an object");
    return options;
};
const ingestProperties = (properties) => {
    properties = properties || {};
    if (!isObject(properties)) throw new Error("properties must be an object");
    if (Object.keys(properties).some((key) => key.startsWith("_")))
        throw new Error(
            "properties must not contain keys that start with an underscore"
        );
    return properties;
};

const ingestSorting = (sortingRef) => {
    sortingRef = sortingRef || ref([]);
    if (!isRef(sortingRef)) sortingRef = ref(sortingRef);
    if (!isArrayOfStrings(sortingRef.value))
        throw new Error(
            'sorting must be an array of strings fx. "_createdAt, desc"'
        );
    sortingRef.value.forEach((sort) => {
        const [attributeName, direction = "desc"] = sort
            .split(",")
            .map((s) => s.trim());
        if (!isJSIdentifier(attributeName))
            throw new Error(
                attributeName,
                "is not a valid attributeName for sorting. Must be a valid JavaScript identifier."
            );
    }, true);
    return sortingRef;
};

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const useDocuments = ({
    alias,
    _appId,
    _dataType,
    operations,
    generators,
    filter,
    sorting,
    offset,
    limit,
    options,
}) => {
    if (!_appId) throw new Error("_appId is required");
    if (!_dataType) throw new Error("_dataType is required");
    offset = offset || 0;
    limit = limit || 100;
    filter = ingestFilter(filter);
    sorting = ingestSorting(sorting);

    alias = ingestAlias(alias);
    operations = ingestOperations(operations);
    generators = ingestGenerators(generators);
    options = ingestOptions(options);

    const { autoLoad = false } = options;

    const store = useStore();
    const documents = ref([]);
    const context = reactive({
        [alias]: documents,
        isWaitingForFilter: true,
        isLoaded: false,
        isLoading: false,
        isPristine: false,
        error: null,
    });

    const listDocuments = async ({
        __appId,
        __dataType,
        __filter,
        offset,
        pageSize,
    }) => {
        context.isWaitingForFilter = false;
        try {
            __appId = __appId || _appId;
            __dataType = __dataType || _dataType;
            __filter = ingestFilter(__filter || filter);

            store.state.docs[__dataType] = store.state.docs[__dataType] || {};

            // setup watch event source
            const repository = computed(() =>
                Object.values(store.state.docs[_dataType] ?? {})
            );

            watch(
                repository,
                (newV) => {
                    filterDocuments(
                        documents,
                        newV,
                        __filter.value,
                        sorting.value
                    );
                },
                { immediate: true }
            );
            watch(
                filter,
                (newV, oldV) => {
                    newV = { ...newV };
                    filterDocuments(
                        documents,
                        repository.value,
                        newV,
                        sorting.value
                    );
                },
                { immediate: true }
            );
            watch(
                sorting,
                (newV) => {
                    filterDocuments(
                        documents,
                        repository.value,
                        __filter.value,
                        newV
                    );
                },
                { immediate: true }
            );

            if (autoLoad) {
                context.isLoading = true;
                if (!_appId)
                    throw new Error("_appId is required to list documents");
                if (!_dataType)
                    throw new Error("_dataType is required to list documents");

                const params = {
                    _appId: __appId,
                    _dataType: __dataType,
                    filter: { ...filter.value },
                };
                if (sorting.value?.length) params.sorting = [...sorting.value];
                if (offset) params.offset = offset;
                if (pageSize) params.pageSize = pageSize;
                if (limit) params.limit = limit;

                if (debug.list)
                    console.log(
                        "listDocuments(params)",
                        operations.listDocuments,
                        params
                    );
                return store
                    .dispatch(operations.listDocuments, params)
                    .then((result) => {
                        filterDocuments(
                            documents,
                            Object.values(store.state.docs[__dataType]),
                            __filter.value,
                            sorting.value
                        );
                        if (debug.list)
                            console.log(
                                "listDocuments.then(result)",
                                result,
                                documents.value
                            );
                        context.isPristine = true;
                        context.isLoaded = true;
                        return result;
                    })
                    .catch((error) => {
                        console.log(487, error);
                        context.error = error;
                    })
                    .finally(() => {
                        context.isLoading = false;
                    });
            } else {
                if (debug.list)
                    console.log(`noAutoLoad ${__dataType}`, autoLoad);
                filterDocuments(
                    documents,
                    Object.values(store.state.docs[__dataType]),
                    __filter.value,
                    sorting.value
                );
                context.isLoaded = true;
            }
        } catch (error) {
            context.error = error;
            console.warn(487, error);
            throw error;
        } finally {
            context.isLoading = false;
        }
    };

    onMounted(() => {
        listDocuments({
            __appId: _appId,
            __dataType: _dataType,
            __filter: filter?.value ?? filter,
            offset,
            pageSize: limit,
        }).catch((error) => (context.error = error));
    });

    const createDocument = async (properties, _parentId) => {
        properties = ingestProperties(properties);
        if (!filter.value._appId)
            throw new Error(
                "filter._appId is required to create new documents"
            );
        if (!filter.value._dataType)
            throw new Error(
                "filter._dataType is required to create new documents"
            );

        properties._appId = filter?.value?._appId;
        properties._dataType = filter?.value?._dataType;

        if (_parentId) properties._parentId = _parentId;

        try {
            context.isLoading = true;
            let generator = generators?.create;
            let call;
            if (generator) {
                call = generator(properties, _parentId);
            } else {
                call = store.dispatch(operations.createDocument, properties);
            }
            return call
                .then((response) => {
                    try {
                        const { data } = response;
                        let { Error } = data;
                        const documents = data[properties._dataType];
                        if (documents && !Error) {
                            documents.map((document) =>
                                documents.push(document)
                            );
                        }
                        context.isPristine = true;
                    } catch (err) {
                        console.warn(err);
                        throw new Error("Server response is malformed");
                    }
                    return response;
                })
                .catch((error) => {
                    console.log(488, error);
                });
        } catch (error) {
            context.error = error;
        } finally {
            context.isLoading = false;
        }
    };

    return {
        ...toRefs(context),
        documents, // for convenience
        listDocuments,
        createDocument,
    };
};
