<script>
import { computed } from "vue";

export default {
    name: "ProgressBar",
    props: {
        value: {
            type: Number,
            default: 0,
            validator: (value) => 0 <= value && value <= 100,
        },
    },
    setup(props) {
        const valueStyle = computed(() => ({
            width: `${props.value}%`,
        }));
        return {
            valueStyle,
        };
    },
};
</script>

<template>
    <div class="progressBar w-full bg-gray-200 rounded-lg h-3 dark:bg-gray-700">
        <div
            class="bg-assistant_primary-500 h-3 rounded-lg text-center"
            :style="valueStyle"
        ></div>
    </div>
</template>

<style scoped></style>
