<script>

import LSection from "@/components/layout/LSection.vue";
import {useDocuments} from '@/composables/document/useDocuments.js'
import LibraryEntry from "./LibraryEntry.vue";
import Button from "@/components/forms/Button.vue";
import {useRouter} from "vue-router";

export default {
  name: "ComponentLibraryHome",
  components: {
    Button,
    LibraryEntry,
    LSection
  },
  setup() {
    const {documents} = useDocuments({
      alias: 'components',
      _appId: 'showcase',
      _dataType: 'VueComponent',
      filter: {
        options: {
          autoLoad: true
        }
      }
    })

    const router = useRouter()
    const onCreateComponent= () => {
      router.push({name: 'createLibraryComponent'})
    }

    return {
      documents,

      onCreateComponent
    }
  }
}
</script>

<template>
  <div class="w-full componentLibraryHome view">
    <LSection>
      <div class="
          mt-10
          flex flex-col
          mx-2 k1:mx-4 k15:mx-6
        "
      >
        <div class="heading1">
          CHI Component Library
        </div>
        <div
            class="
              w-min-[88rem]
              flex flex-col gap-4
            "
        >
          <LibraryEntry
              v-for="(entry, i) in documents"
              :key="entry._id"
              :class="{
                'bg-gray-100': i%2
              }"
              _dataType="VueComponent"
              :_id="entry._id"
          ></LibraryEntry>
          <Button
              title="start component development stream"
              @click="onCreateComponent"
          >
            Create Component
          </Button>
        </div>
      </div>
    </LSection>
  </div>
</template>
