export default [
    {
        path: "/custom",
        name: "customAssistants",
        component: () => import("./components/views/CustomAssistants.vue"),
    },
    {
        path: "/teaching",
        name: "teachingAssistants",
        component: () => import("./components/views/TeachingAssistants.vue"),
    },
    {
        path: "/actions",
        name: "actions",
        component: () => import("./components/views/Action/Actions.vue"),
    },
    {
        path: "/actions/:_actionId",
        name: "action",
        component: () => import("./components/views/Action/Action.vue"),
        props: true,
    },
    {
        path: "/assistant/new",
        name: "assistant",
        component: () => import("./components/views/CreateAssistant.vue"),
        props: true,
        meta: {
            backNavigateTo: "homeCHIAssistants",
        },
    },
    {
        path: "/assistant/:_assistantId",
        name: "assistant",
        component: () => import("./components/views/CustomAssistant.vue"),
        props: true,
    },
    {
        path: "/assistant/:_assistantId/ad",
        name: "assistantAd",
        component: () => import("./components/views/AssistantAdView.vue"),
        props: true,
    },
    {
        path: "/assistant/:_assistantId/thread",
        name: "newChat",
        component: () => import("./components/views/AssistantStream.vue"),
        props: true,
    },
    {
        path: "/assistant/:_assistantId/thread/:thread_id",
        name: "chat",
        component: () => import("./components/views/AssistantStream.vue"),
        props: true,
    },
    // quantum HR assistant
    {
        name: "/quantumHRAssistant",
        path: "/quantumHRAssistant",
        component: () =>
            import(
                "./components/views/QuantumHRAssistant/QuantumHRAssistant.vue"
            ),
    },
    {
        name: "batchImportFiles",
        path: "/batchImport/:fileNamePrefix",
        /*
            imports all existing files as OpenAI files, prepends fileNamePrefix
         */
        component: () =>
            import("./components/views/BatchImportAssistantFiles.vue"),
        props: true,
    },
];
