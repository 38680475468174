import { createApp } from "vue";
import App from "./App.vue";
import router from "./router/router.js";
import store from "./store/store.js";
import FileReference from "../chi-assistants/components/Assistant/Markdown/FileReference.vue";

import "./assets/css/tailwind.css";

// style defaults
import "./index.css";

// highlight.js theme
import "./hljs_github-dark.css";

// custom dayjs
import dayjs from "./plugins/dayjs.js";

// custom axios
import axios from "./plugins/axios.js";

// toastify
import "vue3-toastify";
import "vue3-toastify/dist/index.css";

createApp(App)
    .use(store)
    .provide("AXIOS", axios)
    .provide("DAYJS", dayjs)
    .component("FileReference", FileReference)
    .use(router)
    .mount("#app");
