<script>
import { computed } from "vue";
import { useDocument } from "@/composables/document/useDocument.js";
import { useRouter } from "vue-router";

export default {
    name: "FileReference",
    props: {
        _id: {
            type: String,
            required: true,
        },
        filename: {
            type: String,
            required: true,
        },
        index: {
            type: String,
            required: true,
        },
    },
    setup(props) {
        const router = useRouter();
        const { chunk } = useDocument({
            alias: "chunk",
            filter: {
                _appId: "assistants",
                _dataType: "Chunk",
                _id: props._id,
            },
            options: {
                autoLoad: true,
            },
        });
        const text = computed(() => {
            return (
                chunk.value?.text ??
                `Loading chunk ${props.filename}:${props.index}`
            );
        });

        const onShowTooltip = () =>
            router.push({ query: { modal: "chunk", id: props._id } });

        return {
            text,
            onShowTooltip,
        };
    },
};
</script>

<template>
    <span class="fileReference bg-fuchsia-600" @click="onShowTooltip">
        text
    </span>
</template>
