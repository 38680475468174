<script>
import RecordsMenu from "./GPT Explorer/RecordsMenu.vue";
import {placeholderData} from './RECORDS/search/lib/placeholderData.js';
import {useStore} from "vuex";

export default {
  name: "APMMRecords",
  components: {
    RecordsMenu
  },
  setup () {
    const store = useStore()
    console.log(1, )
    store.state.docs.APMMRecord = store.state.docs?.APMMRecord || placeholderData
  }
}
</script>

<template>
<div class="APMMRecords view">
  <div class="w-full pt-4 px-4 ">
    <RecordsMenu class="mb-10"></RecordsMenu>
    <router-view></router-view>
  </div>
</div>
</template>
