import {capitalize} from "vue";
import {isArray, isObject, isString} from '@mehimself/cctypehelpers';
import {repopulateObject} from '@mehimself/cctypehelpers';
import {state} from '@/store/store.js';
import {areSame} from '@mehimself/cctypehelpers';

const ephemeralTypes = ['_Person']
const initType = (state, _dataType) => state.docs[_dataType] = state.docs[_dataType] || {}
// const performUpdates = (target, update, index) => {
//     const id = update._id ?? update.id ?? index
//     const existingAsset = target[id]
//     if (existingAsset) {
//         if (!areSame(existingAsset, update)) repopulateObject(existingAsset, update) // avoid digestion collision
//     } else {
//         target[id] = update
//     }
// }
const performUpdates = (target, update) => {
    const id = update._id ?? update.id
    const existingAsset = target[id]
    if (existingAsset) {
        if (!areSame(existingAsset, update)) repopulateObject(existingAsset, update)
    } else {
        target[id] = update
    }
}
const performDeletes = (target, deltas) => {
    const deletes = deltas.filter(delta => delta._token === 'delete')
    for (let i = 0; i < deletes.length; i++) {
        const itemDelete = deletes[i]
        if (deletes[i]._dataType === 'Thread') console.log(1, itemDelete._id, target[itemDelete._id])
        if (itemDelete._token === 'delete') {
            delete target[itemDelete._id]
            // console.log(2, itemDelete._id, target[itemDelete._id], state.docs.Thread?.[itemDelete._id])
        }
    }
}
const applyRepoDataType = (deltas, _dataType) => {
    if (_dataType) {
        deltas.forEach(delta => { // for ephemeral documents
            delta._dataType = _dataType
        })
    }
}
const synchronizeDocuments = (repository, deltas) => {
    deltas
        .filter(delta => !delta._token || delta._token !== 'delete') // has no delete token
        // .forEach((delta, index) =>
        //     performUpdates(repository, delta, index)
        // )
        .forEach((delta) =>
            performUpdates(repository, delta)
        )
}
const synchronizeDataType = (typeRepo, deltas, _dataType, resetType) => {
    applyRepoDataType(deltas, _dataType)

    if (resetType) repopulateObject(typeRepo, {})

    synchronizeDocuments(typeRepo, deltas)
    performDeletes(typeRepo, deltas)
}

const applyDelta = (asset, delta) => {
    if (!asset) return
    Object.entries(delta)
        .forEach(([key, value]) => {
            const prop = asset[key]
            if (!['_dataType', 'id', '_id', 'object', 'type', 'name'].includes(key) && isString(prop)) {
                asset[key] += value
            } else if (isObject(prop)) {
                applyDelta(prop, value)
            } else if (isArray(prop)) {
                value.forEach(v => {
                    const {index} = v
                    const itemIndex = prop.findIndex(i => i.index === index)
                    if (itemIndex >= 0) applyDelta(prop[itemIndex], v)
                    else prop.push(v)
                })
            }
            else asset[key] = value
        })
}

export const isStatusVariable = key => key.startsWith('_') && !ephemeralTypes.includes(key)
export const useDataType = (state, _dataType) => {
    _dataType = capitalize(_dataType)
    initType(state, _dataType)

    const typeRepo = state.docs[_dataType]

    const synchronizeState = (items, resetState) => {
        try {
            if (isArray(items)) synchronizeDataType(typeRepo, items, _dataType, resetState)
        } catch (err) {
            console.error(1, 'synchronization error', err, items, resetState)
        }
    }

    const applyAssetDelta = (assetDelta) => {
        try {
            const {_id, id, delta} = assetDelta
            const assetId = _id ?? id

            const asset = typeRepo?.[assetId]

            if (!asset) throw `asset (${_dataType}, ${assetId}) not found`

            applyDelta(asset, delta)

            return asset

        } catch (err) {
            console.error(2, 'apply delta error', err, assetDelta)
        }
    }

    return {
        synchronizeState,
        applyAssetDelta,
    }
}
