import {reactive} from "vue";
import questions from "./questions.js";
import {shuffle} from '@mehimself/cctypehelpers';

const categories = Object.keys(questions);

export const state = reactive({
    startGame: null,
    startRound: null,
    logPrompt: null,
    logResponse: null,
    endGame: null,
    gameStarted: false,
})
const resetState = () => {
    state.currentCategoryIndex = 0;
    const currentCategory = categories[0];
    state.currentCategory = currentCategory;
    state.currentCategoryQuestionCount = questions[currentCategory].length;
    state.currentQuestionIndex = 0;
    state.currentQuestion = questions[currentCategory][0];
    state.totalQuestions = Object.values(questions).reduce((count, questions) => count + questions.length, 0);
    state.totalNonAttention = state.totalQuestions - categories.filter(c => c.startsWith("Attention")).length;
    state.evaluations = Object.fromEntries(
        categories.map(
            category => [
                category,
                {
                    correctAnswers: 0,
                    totalAnswers: 0
                }
            ]
        )
    )
    state.isRunning = false;
    state.score = 0;
}
const scrambleAnswerOptions = () => {
    Object.values(questions).forEach(category =>
        category.forEach(question => {
            shuffle(question.options)
        })
    )
}

const iterateQuestions = () => {
    state.currentQuestionIndex++;
    if (state.currentQuestionIndex >= state.currentCategoryQuestionCount || state.currentCategory.startsWith("Attention")) {
        state.currentCategoryIndex++;
        if (state.currentCategoryIndex === categories.length) {
            state.isRunning = false;
            state.currentCategory = "Feedback"
            state.endGame({
                reason: `completed`,
                status: "completed",
                score: Object.values(state.evaluations).reduce((a, b) => {const val = b.correctAnswers ?? 0; return a + val}, 0) / state.totalNonAttention,
                scoreLayout: JSON.stringify(state.evaluations),
                endedAt: Date.now(),
            })
            return;
        }
        state.currentCategory = categories[state.currentCategoryIndex];
        state.currentQuestionIndex = 0;
        state.startRound({
            startedAt: Date.now(),
            category: state.currentCategory,
        })
    }
    const nextQuestion = questions[state.currentCategory][state.currentQuestionIndex];
    state.currentQuestion = nextQuestion;
    state.logPrompt({
        promptedAt: Date.now(),
        question: JSON.stringify(state.currentQuestion),
        category: state.currentCategory,
        questionIndex: state.currentQuestionIndex,
        correctAnswer: state.currentQuestion.answer,
    })
}

const scoreAnswer = (answer) => {
    const isCorrect = answer === state.currentQuestion.answer;
    if (isCorrect) {
        state.evaluations[state.currentCategory].correctAnswers++;
    }
    state.evaluations[state.currentCategory].totalAnswers++;
    state.logResponse({
        isCorrect,
        answer,
    })
}

export const onAnswer = (answer) => {
    scoreAnswer(answer)
    iterateQuestions()
}

export const resetGame = () => {
    resetState()
    scrambleAnswerOptions()
    state.startGame({
        startedAt: Date.now(),
        questions: JSON.stringify(questions),
    }).then(() => {
        state.startRound({
            startedAt: Date.now(),
            category: state.currentCategory,
        })
    }).then(() => {
        state.logPrompt({
            promptedAt: Date.now(),
            question: JSON.stringify(state.currentQuestion),
            category: state.currentCategory,
            questionIndex: state.currentQuestionIndex,
            correctAnswer: state.currentQuestion.answer,
        })
    })

}
export const onPlayGame = () => {
    resetGame()
    state.isRunning = true;
}
